
// Core
import React, { useCallback, useEffect, useRef, useState } from "react";
import { LoadingOutlined, DeleteFilled, LinkOutlined, WarningOutlined } from "@ant-design/icons";
import { message, Table, Tabs, Pagination, Modal, Checkbox, Avatar, Button, Tooltip, Tag } from "antd";
import CustomSelect from "components/Select";

// Styles
import { Wrapper } from "./styles";

// Types
import { fetchData, fetchDataWithoutSession } from "utils/fetch";
import { restAPIs } from "utils/restAPIs";
import InputField from "components/InputField";
import Loader from "components/Loader";
import { debounce, set } from "lodash";
import { PDFDocument } from 'pdf-lib';

import PDF from 'assets/images/pdf.png'
import DOCX from 'assets/images/docx.png'
import FILE from 'assets/images/file.png'
import LOADER from 'assets/images/loader.gif'
import CHECKMARK from 'assets/images/checkmark.png'


import confirm from "antd/es/modal/confirm";
import Textarea from "components/TextArea";


// Ant Design Table
const { Column } = Table;
const { TabPane } = Tabs;

const Ibnbattuta: React.FC = () => {
    const [activeKey, setActiveKey] = useState('1')
    const [tableLoading, setTableLoading] = useState(false);
    const [isLoadingSubmit, setIsLoadingSubmit] = useState(false)
    const [listTable, setListTable] = useState<any>([]);
    const [selectedItem, setSelectedItem] = useState<any>('');
    const [tablePaginationData, setTablePaginationData] = useState({
        page: 1,
        pageSize: 8,
        totalItems: 0,
    });
    const { totalItems: tableTotalItems, page: tablePage, pageSize: tablePageSize } = tablePaginationData;
    const [modeltext, setModelText] = useState<string>('')
    const [modeldescription, setModelDescription] = useState<string>('')
    const [modeldescription2, setModelDescription2] = useState<string>('')
    const [modelstatus, setModelStatus] = useState<boolean>(false)
    const fileInputRef = useRef<any>(null);
    const [files, setFiles] = useState<any>([]);
    const [messageText, setMessageText] = useState('');

    const handleRowClick = (record: any) => {
        setActiveKey('1')
        setSelectedItem(record);
    }

    const onTablePageChange = (page: number, pageSize?: number) => {
        setTablePaginationData((prev) => ({
            ...prev,
            page,
            pageSize: pageSize || prev.pageSize,
        }));
    };

    const handleFileChange = (e: any) => {
        setFiles(Array.from(e.target.files));
    };

    const handleRemoveFile = (index: any) => {
        setFiles((prevFiles: File[]) => prevFiles.filter((_, i: number) => i !== index));
    };

    const handleOnChange = (e:any) => {
        setMessageText(e);
    };

    const fetchArticles = async (): Promise<string> => {
        try {
            const formData = new FormData();
            formData.append('tripId', selectedItem.tripId);
            formData.append('page', '0');
            formData.append('contentLanguage', '1');
            const res = await fetchData(restAPIs.getArticles(formData));
            if (res?.statusCode === 200) {
                const articlesData = res.articles.map((article: { articleName: string; descriptionStriped: string }) => ({
                    text: article.articleName + article.descriptionStriped
                }));

                let temp = '';
                articlesData.forEach((article: { text: string }) => {
                    temp += article.text;
                });
                return temp;
            }
        } catch (error) {
            console.error('Error fetching articles:', error);
        }
        return '';
    };

    const handleSubmit = async () => {
        setModelText('')
        setModelDescription('')
        setModelDescription2('')
        setModelStatus(false)

        setIsLoadingSubmit(true)
        const formData = new FormData();
        const articles = await fetchArticles();
        formData.append('text', messageText + articles);
        formData.append('trip_id', selectedItem.tripId);
        files.map((file: any) => {
            formData.append('file', file);
        })
        formData.append('tripId', selectedItem.tripId)
        const response = await fetchData(restAPIs.createModel(formData));
        if (response.status_code === 200) {

            const forData = new FormData();
            forData.append('action', 'update');
            forData.append('tripId', selectedItem.tripId);
            forData.append('aiEnabled', '1');
            forData.append('appId', '29');
            const res4 = await fetchData(restAPIs.tripUpdate(forData));

            setModelText('Model Created Successfully')
            setModelDescription('Model has been created successfully. You can view the model in the list of AI models.')
            setModelDescription2('You can also view the model in the list of AI models.')
            setModelStatus(true)
            setFiles([])

            // call fetch list
            const res = await fetchData(restAPIs.getTrips(tablePage));
            if (res?.statusCode === 200) {
                setListTable(res.featured);
                setTablePaginationData((prev) => ({
                    ...prev,
                    totalItems: res.isLastPage ? tablePage * tablePageSize : (tablePage + 1) * tablePageSize,
                }));
            }


        } else {
            setModelText('Model Creation Failed')
            setModelDescription('Model creation failed. Please try again later.')
            setModelDescription2('Please try again later.')
            setModelStatus(false)
        }
    }

    useEffect(() => {
        const fetchList = async () => {
            setTableLoading(true);
            const res = await fetchData(restAPIs.getTrips(tablePage));
            if (res?.statusCode === 200) {
                setListTable(res.featured);
                setTablePaginationData((prev) => ({
                    ...prev,
                    totalItems: res.isLastPage ? tablePage * tablePageSize : (tablePage + 1) * tablePageSize,
                }));
            }
            setTableLoading(false);
        };
        fetchList();
    }, [tablePage, tablePageSize]);


    return (
        <Wrapper>
            <Tabs defaultActiveKey="1" activeKey={activeKey} onChange={setActiveKey} className="__tabs_sql" tabPosition="top" color="primary">
                <TabPane tab={"Create"} key={"1"} >
                    <div className="__create_model">
                        <h1 style={{ margin: '0', fontSize: '2rem' }}>Create</h1>
                        <h1 style={{ margin: '0', fontSize: '2rem', marginBottom: '10px' }}>AI Model</h1>
                        <div className="__maxwidth">
                            <h3>Selected Trip</h3>
                            {selectedItem && <h3>Trip: {selectedItem.tripName}</h3>}
                        </div>
                        <div className="__upload_">
                            <h3>Select file</h3>
                            <div className="__upload_div_sec">
                                <input type="file" name="file" multiple id="file" accept=".pdf,.docx" max={1} onChange={handleFileChange} title="" ref={fileInputRef} />

                                <img src={FILE} alt="" style={{ width: '50px' }} />

                                <h3 className="ant-upload-text">Click or drag files to this area to upload</h3>
                                <p className="ant-upload-hint">For the maximum browser support, upload PDF and Docx</p>
                                <button>Select File(s)</button>
                            </div>
                            {files.length > 0 && <div className="__uploaded_list">
                                <h3>Uploaded Files</h3>
                                {files.map((file: any, index: any) => (
                                    <div key={index}>
                                        <div className="__uploaded_file">
                                            <img src={file?.type == 'application/pdf' ? PDF : DOCX} alt="" />
                                            <span style={{ maxWidth: '350px', textOverflow: 'ellipsis', overflow: 'hidden' }}>{file?.name}</span>
                                        </div>
                                        <div className="__uploaded_file __delete" onClick={() => handleRemoveFile(index)}>
                                            <DeleteFilled />
                                        </div>
                                    </div>
                                ))}
                            </div>}

                        </div>

                        <Textarea
                            type="text"
                            placeholder={'More details'}
                            label={'More details'}
                            value={messageText}
                            onChange={handleOnChange}
                            height={250}
                        />

                        {selectedItem && <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '40px' }}>
                            {!isLoadingSubmit ? <button className="__create_model_btn" onClick={() => handleSubmit()}>Create Model</button>
                                : <button className="__create_model_btn" disabled style={{ cursor: 'no-drop' }}><LoadingOutlined /></button>}
                        </div>}
                    </div>
                </TabPane>
                <TabPane tab={"Trips"} key={"2"}>
                    <div className="__list_books">
                        {/* <div className="__filter_sc">
                            <InputField
                                onChange={onChangeText}
                                value={searchText}
                                placeholder="Search"
                                color="#222"
                            />
                        </div> */}
                        <Table
                            dataSource={listTable}
                            className="__market_table"
                            pagination={false}
                            rowKey="tripId"
                            loading={tableLoading}
                        >

                            <Column title="ID" dataIndex="tripId" key="tripId" className="___col" />
                            <Column title="NAME" dataIndex="tripName" key="tripName" className="___col" />
                            {/* <Column title="PAGES" dataIndex="totalpages" key="totalpages" className="___col" /> */}
                            <Column
                                className="___col_status"
                                title="AI Status"
                                key="isAiEnabled"
                                render={(record: any) => (
                                    <><button className="__hyper" onClick={() => handleRowClick(record)}>Create Model</button></>
                                )}
                            />
                        </Table>
                        <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
                            <Pagination
                                current={tablePage}
                                total={tableTotalItems}
                                pageSize={tablePageSize}
                                onChange={onTablePageChange}
                                showSizeChanger={false}
                                style={{ textAlign: 'right' }}
                            />
                        </div>

                    </div>
                </TabPane>
            </Tabs>

            <Modal
                visible={isLoadingSubmit}
                footer={null}
                closable={false}
                centered
                className="__model"
            >
                <div className="__model_design">
                    <h1>{modeltext}</h1>
                    <img src={modelstatus ? CHECKMARK : LOADER} alt="" style={{ filter: modelstatus ? 'hue-rotate(0deg)' : 'hue-rotate(106deg)' }} />
                    <p style={{ marginBottom: '0px' }}>{modeldescription}</p>
                    <h2 style={{ marginTop: '0px' }}>{modeldescription2}</h2>
                    <div style={{ display: 'flex', justifyContent: 'end' }}>
                        {modelstatus && <button className="__ok_button" onClick={() => { setIsLoadingSubmit(false); setModelStatus(false) }}>Close</button>}
                    </div>
                </div>
            </Modal>
        </Wrapper>
    );
};

export default Ibnbattuta;