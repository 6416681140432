
import React, { useEffect, useState } from "react";
import { LoadingOutlined, DeleteFilled } from "@ant-design/icons";
import { Table, Tabs, Pagination, Modal, Button, Tag } from "antd";
import { fetchData } from "utils/fetch";
import { restAPIs } from "utils/restAPIs";
import { Wrapper } from "./styles";
import CHECKMARK from 'assets/images/checkmark.png';
import LOADER from 'assets/images/loader.gif';
import CustomSelect from "components/Select";

const { Column } = Table;
const { TabPane } = Tabs;

const Electronicvillage: React.FC = () => {
    const [activeKey, setActiveKey] = useState('1');
    const [tableLoading, setTableLoading] = useState(false);
    const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
    const [allData, setAllData] = useState<any[]>([]);
    const [filteredData, setFilteredData] = useState<any[]>([]);
    const [listTable, setListTable] = useState<any[]>([]);
    const [tablePaginationData, setTablePaginationData] = useState({
        page: 1,
        pageSize: 8,
        totalItems: 0,
    });
    const { totalItems, page, pageSize } = tablePaginationData;
    const [modeltext, setModelText] = useState<string>('');
    const [modeldescription, setModelDescription] = useState<string>('');
    const [modeldescription2, setModelDescription2] = useState<string>('');
    const [modelstatus, setModelStatus] = useState<boolean>(false);
    const [selectedFilter, setSelectedFilter] = useState<any>('0');

    const [oneArticle, setOneArticle] = useState<any>('')

    const paginateData = (data: any[], currentPage: number, pageSize: number) => {
        const startIndex = (currentPage - 1) * pageSize;
        const paginatedData = data.slice(startIndex, startIndex + pageSize);
        setListTable(paginatedData);
    };

    const fetchList = async () => {
        setTableLoading(true);
        const res = await fetchData(restAPIs.getEvArticles(selectedFilter));
        if (res?.statusCode === 200) {
            setOneArticle(res.articles[0])
            setAllData(res.articles);
            setFilteredData(res.articles);
            setTablePaginationData(prev => ({
                ...prev,
                totalItems: res.articles.length,
            }));
            paginateData(res.articles, 1, pageSize);
        }
        setTableLoading(false);
    };

    useEffect(() => {
        fetchList();
    }, [selectedFilter]);

    const onTablePageChange = (current: number, size?: number) => {
        setTablePaginationData(prev => ({
            ...prev,
            page: current,
            pageSize: size || prev.pageSize,
        }));
        paginateData(filteredData, current, size || pageSize);
    };

    const filterOptions = [
        { value: '1', label: 'AI Enabled' },
        { value: '0', label: 'AI Not Enabled' },
    ];

    const handleOnChangeFilter = (value: string) => {
        // reset pagination
        setTablePaginationData(prev => ({
            ...prev,
            page: 1,
        }));
        paginateData(filteredData, 1, pageSize);

        setSelectedFilter(value);
    };

    const handleSubmit = async (articleId:any) => {
        let concatenatedString = '';
        allData.forEach(item => {
            concatenatedString += `Article Name :${item.name} \n Article Description : ${item.stripedBody} \n Article Id: ${item.id} \n Category Name : ${item.categoryName} \n Category Id : ${item.category}\n \n`;
        });
        const formData = new FormData();
        formData.append('text', concatenatedString);
        formData.append('article_id', 'articleAll');
        setIsLoadingSubmit(true);
        const res = await fetchData(restAPIs.createEVModel(formData));
        if (res?.status_code === 200) {
            setModelText('Model Created Successfully');
            setModelDescription('Your model has been created successfully.');
            setModelDescription2('You can now use this model for prediction.');
            for (const item of allData) {
                const forData = new FormData();
                forData.append('action', 'update');
                forData.append('articleId', item.id);
                forData.append('aiEnabled', '1');
                forData.append('appId', '31');
                await fetchData(restAPIs.evArticleUpdate(forData));
            }
            setModelStatus(true);
            // call fetch list
            fetchList();
        } else {
            setModelText('Model Creation Failed');
            setModelDescription('There was an error while creating the model.');
            setModelDescription2('Please try again later.');
            setModelStatus(false);
        }
    }

    return (
        <Wrapper>
            <Tabs activeKey={activeKey} onChange={setActiveKey} className="__tabs_sql" tabPosition="top">
                <TabPane tab="Articles" key="1">
                    <div className="__list_books">
                        <div className="__filter_sc">
                            {selectedFilter == 0 && <div style={{ display: 'flex', justifyContent: 'flex-end' }} >
                                {!isLoadingSubmit ? <button className="__create_model_btn" onClick={() => handleSubmit(1)} style={{ height: '56px', cursor: tableLoading ? 'no-drop' : 'pointer' }} disabled={tableLoading}>Create Model</button>
                                    : <button className="__create_model_btn" disabled style={{ cursor: 'no-drop', height: '56px' }}><LoadingOutlined /></button>}
                            </div>}
                            <CustomSelect
                                options={filterOptions}
                                value={selectedFilter}
                                allowClear={false}
                                onChangeHandler={handleOnChangeFilter}
                            />
                        </div>
                        <Table
                            dataSource={listTable}
                            pagination={false}
                            rowKey="id"
                            loading={tableLoading}
                        >
                            <Column title="ID" dataIndex="id" key="id" />
                            <Column title="NAME" dataIndex="name" key="name" />
                            <Column
                                title="AI Status"
                                key="isAiEnabled"
                                render={(record: any) => (
                                    <Tag color={record.isAiEnabled === 1 ? 'success' : 'error'}>
                                        {record.isAiEnabled === 1 ? "Enabled" : "Not Enabled"}
                                    </Tag>
                                )}
                            />
                        </Table>
                        <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 20 }}>
                            <Pagination
                                current={page}
                                total={totalItems}
                                pageSize={pageSize}
                                onChange={onTablePageChange}
                                showSizeChanger={false}
                            />
                        </div>
                    </div>
                </TabPane>
            </Tabs>

            <Modal
                visible={isLoadingSubmit}
                footer={null}
                closable={false}
                centered
                className="__model"
            >
                <div className="__model_design">
                    <h1>{modeltext}</h1>
                    <img src={modelstatus ? CHECKMARK : LOADER} alt="" />
                    <p>{modeldescription}</p>
                    <h2>{modeldescription2}</h2>
                    {modelstatus && <Button onClick={() => setIsLoadingSubmit(false)}>Close</Button>}
                </div>
            </Modal>
        </Wrapper>
    );
};

export default Electronicvillage;
