// import React, { useState } from "react";
// import Select, { SelectProps } from "antd/es/select";
// import { Wrapper } from "./styles";
// import { Props } from "./types";
// import { FontSizes, FontWeight } from "utils/enums";
// import { colors } from "assets/styles/colors";
// import { ErrorLabel } from "components/InputField/styles";
// import { Label } from "assets/styles/globalStyles";
// import { Pagination } from "antd";

// const CustomSelect: React.FC<any> = (props: any) => {
//   const {
//     options,
//     defaultValue,
//     onChangeHandler,
//     value,
//     disabled,
//     label,
//     placeholder,
//     isMandatory,
//     error,
//     viewMode,
//     pagination,
//     onPaginationChange,
//     onSearch
//   } = props;

//   const [currentPage, setCurrentPage] = useState(1);
//   const [pageSize, setPageSize] = useState(20);

//   const handlePageChange = (page: number, pageSize: number) => {
//     setCurrentPage(page);
//     setPageSize(pageSize);
//     if (onPaginationChange) {
//       onPaginationChange(page, pageSize);
//     }
//   };


//   const handleChange = (value: any) => {
//     if (onChangeHandler) {
//       onChangeHandler(value);
//     }
//   };

//   return (
//     <Wrapper>
//       {label && (
//         <Label
//           marginBottom={viewMode ? 0 : 5}
//           fontSize={FontSizes.floatingLabel}
//           color={colors.default}
//           fontWeight={FontWeight.default}
//         >
//           {label} {isMandatory && !viewMode && <span>*</span>}
//         </Label>
//       )}
//       {viewMode ? (
//         <>
//           <Label
//             marginBottom={5}
//             fontSize={FontSizes.input}
//             color={colors.default}
//             fontWeight={FontWeight.bold}
//           >
//             {value || "-"}
//           </Label>
//         </>
//       ) : (
//         <>
//           <Select
//             value={value}
//             showSearch
//             optionFilterProp="label"
//             placeholder={label || placeholder}
//             disabled={disabled}
//             defaultValue={defaultValue}
//             onChange={handleChange}
//             options={options}
//             status={error ? "error" : ""}
//             onSearch={onSearch}
//             dropdownRender={menu => (
//               <>
//                 {menu}
//                 {pagination && (
//                   <div style={{ padding: '8px', textAlign: 'right' }}>
//                     <Pagination
//                       current={currentPage}
//                       pageSize={pageSize}
//                       total={pagination.total}
//                       onChange={handlePageChange}
//                       showSizeChanger={false}
//                     />
//                   </div>
//                 )}
//               </>
//             )}
//           />
//           {!!error && (
//             <ErrorLabel
//               fontSize={FontSizes.error}
//               color={colors.error}
//               fontWeight={FontWeight.light}
//             >
//               {error}
//             </ErrorLabel>
//           )}
//         </>
//       )}
//     </Wrapper>
//   );
// };

// export default CustomSelect;

import React, { useState, useEffect } from "react";
import Select, { SelectProps } from "antd/es/select";
import { Wrapper } from "./styles";
import { Props } from "./types";
import { FontSizes, FontWeight } from "utils/enums";
import { colors } from "assets/styles/colors";
import { ErrorLabel } from "components/InputField/styles";
import { Label } from "assets/styles/globalStyles";
import { Pagination, Spin } from "antd";

const CustomSelect: React.FC<any> = (props: any) => {
  const {
    options,
    defaultValue,
    onChangeHandler,
    value,
    disabled,
    label,
    placeholder,
    isMandatory,
    error,
    viewMode,
    pagination,
    onPaginationChange,
    onSearch,
    loading,
    onClear,
    allowClear
  } = props;
  

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);

  const handlePageChange = (page: number, pageSize: number) => {
    setCurrentPage(page);
    setPageSize(pageSize);
    if (onPaginationChange) {
      onPaginationChange(page, pageSize);
    }
  };

  const handleChange = (value: any) => {
    if (onChangeHandler) {
      onChangeHandler(value);
    }
  };

  return (
    <Wrapper>
      {label && (
        <Label
          marginBottom={viewMode ? 0 : 5}
          fontSize={FontSizes.floatingLabel}
          color={colors.default}
          fontWeight={FontWeight.default}
        >
          {label} {isMandatory && !viewMode && <span>*</span>}
        </Label>
      )}
      {viewMode ? (
        <>
          <Label
            marginBottom={5}
            fontSize={FontSizes.input}
            color={colors.default}
            fontWeight={FontWeight.bold}
          >
            {value || "-"}
          </Label>
        </>
      ) : (
        <>
          <Select
            value={value}
            showSearch
            optionFilterProp="label"
            placeholder={label || placeholder}
            disabled={disabled}
            defaultValue={defaultValue}
            onChange={handleChange}
            options={options}
            status={error ? "error" : ""}
            onClear={onClear}
            onSearch={onSearch}
            allowClear={allowClear}
            dropdownRender={menu => (
              <>
                {loading ? (
                  <div style={{ textAlign: 'center', padding: 16 }}>
                    <Spin />
                  </div>
                ) : (
                  menu
                )}
                {pagination && !loading && (
                  <div style={{ padding: '8px', textAlign: 'right' }}>
                    <Pagination
                      current={currentPage}
                      pageSize={pageSize}
                      total={pagination.total}
                      onChange={handlePageChange}
                      showSizeChanger={false}
                    />
                  </div>
                )}
              </>
            )}
          />
          {!!error && (
            <ErrorLabel
              fontSize={FontSizes.error}
              color={colors.error}
              fontWeight={FontWeight.light}
            >
              {error}
            </ErrorLabel>
          )}
        </>
      )}
    </Wrapper>
  );
};

export default CustomSelect;
