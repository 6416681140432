import React, { Suspense } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import Loader from 'components/Loader';
import Header from 'components/Header';
import Login from 'pages/Login';
import ProtectedRoute from './ProtectedRoute';
import { isAuthenticated } from 'utils/helpers';
import Homepage from 'pages/Homepage';
import Ibnbattuta from 'pages/Ibnbattuta';
import Electronicvillage from 'pages/Electronicvillage';


const AppRouter = () => {
  const isLogin = window.location.href.includes('login');

  return (
    <>
      <BrowserRouter>
        <div className='__dark' style={{ padding: isLogin ? '0px' : '20px' }}>
        <Header />
          <Suspense fallback={<Loader />}>
            <Routes>
              {/* Private Routes */}
                <Route element={<ProtectedRoute />}>
                <Route path="/alwaraq" element={<Homepage />} />
                <Route path="/ibn-battuta" element={<Ibnbattuta />} />
                <Route path="/electronicvillage" element={<Electronicvillage />} />
                <Route path="*" element={<Navigate to="/alwaraq" />} />
                </Route>

              {/* Public Routes */}
              <Route
                path="/login"
                element={isAuthenticated() ? <Navigate to="/home" /> : <Login />}
              />
            </Routes>
          </Suspense>
        </div>
      </BrowserRouter>
    </>
  );
};

export default AppRouter;
