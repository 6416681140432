import { colors } from "assets/styles/colors";
import styled from "styled-components";

export const Wrapper = styled.div`
    padding: 30px;
    padding-top: 0px;
    min-height: calc(70vh);
    position: relative;
    max-width:80vw;
    margin:0 auto;
    background:#fff;
    border-radius:10px;
    box-shadow:0px 0px 10px #ccc3;


    .__maxwidth {
        max-width:35vw;
        @media (max-width: 556px) {
            max-width:85vw;
        }
    }

    .ant-tabs {
        .ant-tabs-tab {
            font-size: 1.2rem;
            font-weight: 600;
        }
    }
    .__upload_ {
        margin:15px 0;
        h3 {
            margin-bottom:5px;
        }
        .ant-upload-drag {
            border: 2px dashed #d9d9d9;
            border-radius: 15px;
        }

         button {
                padding: 15px 40px;
                color:#777;
                border: 1px solid #777;
                background: #fff;
                border-radius: 7px;
                margin-top: 20px;
                font-size: 1rem;
                font-weight: 700;
            }
           

        .__upload_div_sec {
            border: 2px dashed #ccc;
            border-radius: 20px;
            padding: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            cursor:pointer;
            transition:0.4s;
            position:relative;
            &:hover{
            border-color:#d7907a;
            }
            p {
                margin:0;
                font-weight:600;
            }
            input {
                width: 100%;
                height: 100%;
                position: absolute;
                opacity: 0;
                cursor:pointer;
                z-index:0;
            }
        }
    }

.__uploaded_list {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-top:20px;
    .__uploaded_file {
        color:#666;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        font-weight: 600;
        padding: 10px 15px;
        border: .15rem solid #eee;
        border-radius: 10px;
        height: 38px;
        min-width: 28px;
        font-size: 1rem;
        transition:0.4s;
        img {
            width: 40px;
        }
    }
        .__delete {
            cursor:pointer;
                &:hover{
            border-color:#ccc;
            }
        }
}


    .__create_model_btn {
        cursor:pointer;
        padding: 20px 30px;
        border: none;
        border-radius: 12px;
        background: linear-gradient(90deg, #f78f93, #fc51a2);
        color: #fff;
        font-size: 1.2rem;
        font-weight: 600;
        box-shadow: 0px 0px 10px #0002;
        height: 62px;
        width: 185px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .__not_enabled {
        border-radius:100px;
        background:${colors?.primary};
        border:none;
        color:#fff;
        font-weight:600;
        padding:5px 10px;
    }
    .__enabled {
        border-radius:100px;
        background:green;
        border:none;
        color:#fff;
        font-weight:600;
        padding:5px 10px;
    }
    .ant-table {
        border: 1px solid #ddd;
        border-radius: 12px !important;
        overflow: hidden;
        font-weight: 600;
        font-size: 1rem !important;
    }
    .___col {
        min-width: 100px;
    }
    .___col_status{
        min-width: 140px;
    }
    .__filter_sc {
        display:grid;
        grid-template-columns:1fr 200px;
        gap:20px;
        margin-bottom:20px;
    }   

    .__dots {
        display:flex;
        align-items:center;
        gap:15px;
        span {
                width: 25px;
                height: 15px;
                background: #80808057;
                border-radius: 100px;
            }
            .green {
                background:#5cb55c;
            }
        
    }
    .__hyper{
        font-size: 13px;
        background: #fff;
        border: 1px solid;
        padding: 5px 10px;
        text-decoration: none;
        border-radius: 14px;
        cursor: pointer;
    }
`;
