
// Core
import React, { useCallback, useEffect, useRef, useState } from "react";
import { LoadingOutlined, DeleteFilled, LinkOutlined, WarningOutlined } from "@ant-design/icons";
import { message, Table, Tabs, Pagination, Modal, Checkbox, Avatar, Button, Tooltip, Tag } from "antd";
import CustomSelect from "components/Select";

// Styles
import { HomepageWrapper } from "./styles";

// Types
import { fetchData, fetchDataWithoutSession } from "utils/fetch";
import { restAPIs } from "utils/restAPIs";
import InputField from "components/InputField";
import Loader from "components/Loader";
import { debounce } from "lodash";
import { PDFDocument } from 'pdf-lib';

import PDF from 'assets/images/pdf.png'
import DOCX from 'assets/images/docx.png'
import FILE from 'assets/images/file.png'
import LOADER from 'assets/images/loader.gif'
import CHECKMARK from 'assets/images/checkmark.png'


import confirm from "antd/es/modal/confirm";


// Ant Design Table
const { Column } = Table;
const { TabPane } = Tabs;

interface QuizResponse {
    questionAr: string;
    questionEn: string;
    answerAr_1: string;
    answerAr_2: string;
    answerAr_3: string;
    answerAr_4: string;
    answerEn_1: string;
    answerEn_2: string;
    answerEn_3: string;
    answerEn_4: string;
    correctAnswer: string;
}

const Homepage: React.FC = () => {
    const [pageLoading, setPageLoading] = useState(false);
    const [quizLoading, setQuizLoading] = useState('');
    const [tableLoading, setTableLoading] = useState(false);
    const [isLoadingSubmit, setIsLoadingSubmit] = useState(false)
    const [dropdownLoading, setDropdownLoading] = useState(false);

    const [searchText, setSearch] = useState<any>('');
    const [dropdownSearchText, setDropdownSearchText] = useState<string>('');

    const [booksList, setBooksList] = useState<any>([]);
    const [booksListTable, setBooksListTable] = useState<any>([]);
    const [selectedBookId, setSelectedBookId] = useState<any>('');
    const [selectedBook, setSelectedBook] = useState<any>('');
    const [uploadedFile, setUploadedFile] = useState<any>(null);
    const [tablePaginationData, setTablePaginationData] = useState({
        page: 1,
        pageSize: 8,
        totalItems: 0,
    });
    const [dropdownPaginationData, setDropdownPaginationData] = useState({
        page: 1,
        pageSize: 50,
        totalItems: 0,
    });
    const { totalItems: tableTotalItems, page: tablePage, pageSize: tablePageSize } = tablePaginationData;
    const { totalItems: dropdownTotalItems, page: dropdownPage, pageSize: dropdownPageSize } = dropdownPaginationData;
    const fileInputRef = useRef<any>(null);
    const [selectedFilter, setSelectedFilter] = useState<any>('2');
    const [textInputs, setTextInputs] = useState('')
    const [activeKey, setActiveKey] = useState('1')
    const [modeltext, setModelText] = useState<string>('')
    const [modeldescription, setModelDescription] = useState<string>('')
    const [modeldescription2, setModelDescription2] = useState<string>('')
    const [modelstatus, setModelStatus] = useState<boolean>(false)

    const [selectedBooks, setSelectedBooks] = useState<any[]>([]);

    useEffect(() => {
        getBooksForTable(tablePage, tablePageSize);
    }, [tablePage, tablePageSize]);

    useEffect(() => {
        getBooksForDropdown(dropdownPage, dropdownPageSize, dropdownSearchText);
    }, [dropdownPage, dropdownPageSize, dropdownSearchText]);

    const getBooksForTable = async (page: number, pageSize: number) => {
        setTableLoading(true);
        const res = await fetchDataWithoutSession(restAPIs.bookList(page, pageSize, selectedFilter, btoa(unescape(encodeURIComponent(searchText)))));
        if (res.statusCode === 200) {
            const items: any = res.books.map((element: any) => ({ label: element.name, id: element.bookid, ...element }));
            setBooksListTable(items);
            setTablePaginationData((prev) => ({
                ...prev,
                totalItems: res.total,
            }));
        }
        setTableLoading(false);
    };

    const getBooksForDropdown = async (page: number, pageSize: number, searchText: string) => {
        setDropdownLoading(true)
        setBooksList([])
        const res = await fetchDataWithoutSession(restAPIs.bookList(page, pageSize, 2, btoa(unescape(encodeURIComponent(searchText)))));
        if (res.statusCode === 200) {
            const items: any = res.books.map((element: any) => ({ label: element.name, id: element.bookid, value: element.bookid, ...element }));
            setBooksList(items);
            setDropdownLoading(false)
            setDropdownPaginationData((prev) => ({
                ...prev,
                totalItems: res.total,
            }));
        }
    };

    const handleOnChangeBook = (value: any) => {
        setSelectedBookId(value);
        const selected = booksList.find((item: any) => item.bookid === value);
        setSelectedBook(selected);
        handleTextInputforPDf(value)

    };

    const handleTextInputforPDf = async (bookId: any) => {
        const res = await fetchDataWithoutSession(restAPIs.bookPages(bookId));
        setTextInputs(res?.fullBookPages)
    }
    const handleSubmit = async () => {
        setModelStatus(false)
        if (!selectedBookId) {
            message.error('Please select a book before submitting.');
            return;
        }
        // if (!uploadedFile && (!selectedBook?.isPdfAvailable)) {
        //     message.error('Please upload a file or ensure the selected book has a PDF available.');
        //     return;
        // }
        setIsLoadingSubmit(true)
        setModelText('Creating new model...')
        setModelDescription('Generating PDF document')
        setModelDescription2('Please wait for a while, it takes minutes')
        let items: any = [];
        let mergedPdf: any = null;
        if (selectedBook?.bookPDFs.length !== 0) {
            items = selectedBook?.bookPDFs.map((element: any) => element.pdfFile);
            mergedPdf = await mergePdfsToFile([items]);
        }
        const formData = new FormData();
        formData.append('document_id', selectedBookId);
        if (selectedBook?.isPdfAvailable || uploadedFile)
            formData.append('file', (selectedBook?.isPdfAvailable || selectedBook?.isTextAvailable) ? mergedPdf : uploadedFile);
        formData.append('text', textInputs);
        setModelDescription('Generating new model')
        const res = await fetchData(restAPIs.createModal(formData));
        if (res.status === 'success') {
            const formData2 = new FormData();
            formData2.append('action', 'update');
            formData2.append('bookId', selectedBookId);
            formData2.append('appId', '1');
            formData2.append('aiEnabled', '1');
            const res2 = await fetchData(restAPIs.bookUpdate(formData2));
            // setIsLoadingSubmit(false)
            setUploadedFile(null)
            setSelectedBook('')
            setSelectedBookId('')
            setBooksList([])
            setBooksListTable([])
            setModelText('New model created successfully')
            setModelDescription('')
            setModelDescription2('')
            setModelStatus(true)
            handleOnChangeFilter('2')
            setDropdownLoading(true)
            setDropdownPaginationData((prev) => ({
                ...prev,
                page: 1,
                pageSize: 20,
            }));
            const res3 = await fetchDataWithoutSession(restAPIs.bookList(1, 20, 2, ''));
            if (res3.statusCode === 200) {
                const items: any = res3.books.map((element: any) => ({ label: element.name, id: element.bookid, value: element.bookid, ...element }));
                setBooksList(items);
                setDropdownLoading(false)
                setDropdownPaginationData((prev) => ({
                    ...prev,
                    totalItems: res.total,
                }));
            }

        }
        else {
            setIsLoadingSubmit(false)
            message.error('Model not created.');
            confirm({
                title: 'Error',
                icon: <WarningOutlined style={{ color: 'red' }} />,
                content: 'Model not created',
                onOk() {
                    console.log('OK');
                },
                onCancel() {
                    console.log('Cancel');
                },
            });
        }
    };

    const onTablePageChange = (page: number, pageSize?: number) => {
        setTablePaginationData((prev) => ({
            ...prev,
            page,
            pageSize: pageSize || prev.pageSize,
        }));
    };

    const onDropdownPageChange = (page: number, pageSize?: number) => {
        setDropdownPaginationData((prev) => ({
            ...prev,
            page,
            pageSize: pageSize || prev.pageSize,
        }));
    };

    const handleOnChangeFilter = async (e: any) => {
        setSelectedFilter(e);
        setTablePaginationData((prev) => ({
            page: 1,
            pageSize: 8,
            totalItems: 0
        }));
        setTableLoading(true);
        const res = await fetchDataWithoutSession(restAPIs.bookList(1, 8, e, btoa(unescape(encodeURIComponent(searchText)))));
        if (res.statusCode === 200) {
            const items: any = res.books.map((element: any) => ({ label: element.name, id: element.bookid, ...element }));
            setBooksListTable(items);
            setTablePaginationData((prev) => ({
                ...prev,
                totalItems: res.total,
            }));
        }
        setTableLoading(false);
    };

    const filterOptions = [
        { value: '2', label: 'All' },
        { value: '1', label: 'AI Enabled' },
        { value: '0', label: 'AI Not Enabled' },
    ];


    const handleSearch = async (searchText: string) => {
        setTableLoading(true);
        const res = await fetchDataWithoutSession(restAPIs.bookList(1, 8, selectedFilter, btoa(unescape(encodeURIComponent(searchText)))));
        if (res.statusCode === 200) {
            const items: any = res.books.map((element: any) => ({ label: element.name, id: element.bookid, ...element }));
            setBooksListTable(items);
            setTablePaginationData((prev) => ({
                ...prev,
                totalItems: res.total,
            }));
        }
        setTableLoading(false);
    };


    const debouncedSearch = useCallback(debounce(handleSearch, 300), [selectedFilter]);

    const onChangeText = (value: string) => {
        setSearch(value);
        debouncedSearch(value);
    };

    const handleDropdownSearch = async (searchText: string) => {
        setDropdownPaginationData((prev) => ({
            page: 1,
            pageSize: 20,
            totalItems: 0,
        }));
        const res = await fetchDataWithoutSession(restAPIs.bookList(1, 20, 2, btoa(unescape(encodeURIComponent(searchText)))));
        if (res.statusCode === 200) {
            console.log("called");
            setBooksList([]);
            const items: any = res.books.map((element: any) => ({ label: element.name, id: element.bookid, value: element.bookid, ...element }));
            setBooksList(items);
            // setDropdownPaginationData((prev) => ({
            //     ...prev,
            //     totalItems: res.total,
            // }));
        }
    };

    const debouncedDropdownSearch = useCallback(
        debounce((value: string) => handleDropdownSearch(value), 300),
        [] // Ensure it's created only once on mount
    );

    const onDropdownSearchTextChange = (value: string) => {
        setDropdownSearchText(value);
        debouncedDropdownSearch(value);
    };

    const onClearDropdown = () => {
        setTablePaginationData((prev) => ({
            ...prev,
            page: 1,
            pageSize: 20,
        }));
        setDropdownSearchText('');
        debouncedDropdownSearch('');
    };

    const handleOnchangeFile = (e: any) => {
        const file = e.target.files[0]; // Get the first file (you mentioned max={1} so it's the only one)
        setUploadedFile(file); // Assuming setUploadedFile is a state setter
    };

    const handleRemoveFile = () => {
        setUploadedFile(null); // Clear the uploaded file from state
        fileInputRef.current.value = ""; // Clear the file input value
    }

    const mergePdfsToFile = async (pdfFiles: any) => {
        try {
            // Create a new PDFDocument
            const mergedPdf = await PDFDocument.create();

            for (const pdfFile of pdfFiles) {
                try {
                    if (pdfFile instanceof File) {
                        const arrayBuffer = await pdfFile.arrayBuffer();
                        const pdf = await PDFDocument.load(arrayBuffer);

                        const copiedPages = await mergedPdf.copyPages(pdf, pdf.getPageIndices());
                        copiedPages.forEach((page: any) => mergedPdf.addPage(page));
                    } else {
                        const response = await fetch(pdfFile);
                        if (!response.ok) throw new Error("Failed to fetch PDF");

                        const arrayBuffer = await response.arrayBuffer();
                        const pdf = await PDFDocument.load(arrayBuffer);

                        const copiedPages = await mergedPdf.copyPages(pdf, pdf.getPageIndices());
                        copiedPages.forEach((page: any) => mergedPdf.addPage(page));
                    }
                } catch (error) {
                    console.error(`Error processing PDF file ${pdfFile}:`, error);
                }
            }

            // Serialize the merged PDF to bytes (Uint8Array)
            const mergedPdfBytes = await mergedPdf.save();

            // Create a Blob from the merged PDF bytes
            const blob = new Blob([mergedPdfBytes], { type: 'application/pdf' });

            // Trigger download
            // const url = URL.createObjectURL(blob);
            // const a = document.createElement('a');
            // a.href = url;
            // a.download = 'merged.pdf';
            // a.click();
            // URL.revokeObjectURL(url);

            // Create a File from the Blob
            const mergedPdfFile = new File([blob], 'merged.pdf', { type: 'application/pdf' });

            return mergedPdfFile;
        } catch (error) {
            console.error("Error merging PDFs:", error);
            throw error;
        }
    };

    if (pageLoading) return <Loader />;

    const handleRowClick = (record: any) => {
        onDropdownSearchTextChange(record?.name)
        setActiveKey('1')
        setSelectedBookId(record?.bookid);
        setSelectedBook(record);
        handleTextInputforPDf(record?.bookid)
    }

    const handleSelectBook = (record: any, isSelected: boolean) => {
        if (isSelected) {
            if (selectedBooks.length < 10) {
                setSelectedBooks([...selectedBooks, record]); // Add book to selectedBooks
            }
            else {
                message.error('You can select only 10 books at a time.')
            }
        } else {
            setSelectedBooks(selectedBooks.filter(book => book.bookid !== record.bookid)); // Remove book from selectedBooks
        }
    };

    const handleRemoveBook = (bookid: string) => {
        setSelectedBooks(selectedBooks.filter(book => book.bookid !== bookid)); // Remove book from selectedBooks
    };

    const isSelected = (bookid: string) => {
        return selectedBooks.some(book => book.bookid === bookid); // Check if a book is selected
    };

    const handleSelectAll = (isChecked: boolean) => {
        if (isChecked) {
            const newSelectedBooks = [
                ...selectedBooks,
                ...booksListTable.filter(
                    (book: { bookid: string; }) => !isSelected(book.bookid) && selectedBooks.length < 10
                ),
            ];
            if (newSelectedBooks.length > 10) {
                message.error("You can select only 10 books at a time.");
            }
            setSelectedBooks(newSelectedBooks.slice(0, 10)); // Limit to 10 books
        } else {
            // Deselect all books in the current view
            setSelectedBooks(selectedBooks.filter(book => !booksListTable.some((b: { bookid: any; }) => b.bookid === book.bookid)));
        }
    };

    // Check if all books on the current page are selected
    const isAllSelected = () => {
        return booksListTable.every((book: any) => isSelected(book.bookid)) && booksListTable.length > 0;
    };

    // Check if the "Select All" checkbox should be in an indeterminate state
    const indeterminate = () => {
        return selectedBooks.length > 0 && !isAllSelected();
    };

    const handleBatchSubmit = async () => {
        setModelStatus(false);
        if (selectedBooks.length === 0) {
            message.error('Please select at least one book before submitting.');
            return;
        }

        let successCount = 0;
        let failedBooks: any[] = [];

        setIsLoadingSubmit(true);
        setModelText('Batch submission in progress...');
        setModelDescription('Processing the selected books');

        for (let i = 0; i < selectedBooks.length; i++) {
            const book = selectedBooks[i];
            setModelDescription2(`Processing ${i + 1}/${selectedBooks.length} - ${book.name}`);

            try {
                const resTextInput = await fetchDataWithoutSession(restAPIs.bookPages(book.bookid));
                const textInput = resTextInput?.fullBookPages; // Use the fetched text input
                const items: any = book?.bookPDFs.map((element: any) => element.pdfFile);
                const mergedPdf = await mergePdfsToFile([items]);
                const formData = new FormData();
                formData.append('document_id', book.bookid);
                if (book?.isPdfAvailable)
                    formData.append('file', (mergedPdf));
                formData.append('text', textInput);

                setModelDescription(`Generating model for ${book.name}`);
                const res = await fetchData(restAPIs.createModal(formData));

                if (res.status === 'success') {
                    const formData2 = new FormData();
                    formData2.append('action', 'update');
                    formData2.append('bookId', book.bookid);
                    formData2.append('appId', '1');
                    formData2.append('aiEnabled', '1');

                    const res2 = await fetchData(restAPIs.bookUpdate(formData2));

                    successCount++;
                    setModelDescription2(`${successCount}/${selectedBooks.length} books processed successfully.`);

                } else {
                    throw new Error('Model creation failed');
                }

            } catch (error) {
                failedBooks.push(book.name);
                setModelDescription2(`${i + 1}/${selectedBooks.length} - ${book.name} failed to process.`);
            }
        }


        if (failedBooks.length > 0) {
            message.error(`Batch completed with some errors. Failed books: ${failedBooks.join(', ')}`);
            setModelStatus(true)
            confirm({
                title: 'Batch Error',
                icon: <WarningOutlined style={{ color: 'red' }} />,
                content: `The following books failed to process: ${failedBooks.join(', ')}`,
                onOk() {
                    console.log('OK');
                },
                onCancel() {
                    console.log('Cancel');
                },
            });
        } else {
            // message.success('Batch completed successfully.');
            setModelText('Models created successfully')
            setModelStatus(true)
        }
        setModelText('Models created successfully')
        // Reset state after batch completion
        setUploadedFile(null);
        setSelectedBook('');
        setSelectedBookId('');
        setBooksList([]);
        setBooksListTable([]);
        setModelDescription('');
        setModelDescription2('');
        handleOnChangeFilter('2');
        setSelectedBooks([])
    };

    const handleAskQuiz = async (record: any) => {
        setQuizLoading(record.bookid);
        const quiz = `
                Make a question in english and arabic from this book and give multiple choice answers and say the correct answer also in the below format.
                English Question :
                A)  
                B)    
                C)    
                D)
                Arabic Question :
                A)  
                B)    
                C)    
                D)
                Correct Answer : A) or B) or C) or D)
        `;
        try {
            const res = await fetchData(restAPIs.createAnswer(quiz, record?.bookid));
            if (res.status_code === 200) {


                const regex = /English Question:\s*(.*?)\s*A\)\s*(.*?)\s*B\)\s*(.*?)\s*C\)\s*(.*?)\s*D\)\s*(.*?)\s*Arabic Question:\s*(.*?)\s*A\)\s*(.*?)\s*B\)\s*(.*?)\s*C\)\s*(.*?)\s*D\)\s*(.*?)\s*Correct Answer:\s*(A\)|B\)|C\)|D\))/;
                const match = res.answer.match(regex);


                if (match) {
                    const quizResponse: QuizResponse = {
                        questionAr: (match as RegExpMatchArray)[6].trim(),
                        questionEn: match[1].trim(),
                        answerAr_1: match[7].trim(),
                        answerAr_2: match[8].trim(),
                        answerAr_3: match[9].trim(),
                        answerAr_4: match[10].trim(),
                        answerEn_1: match[2].trim(),
                        answerEn_2: match[3].trim(),
                        answerEn_3: match[4].trim(),
                        answerEn_4: match[5].trim(),
                        correctAnswer: match[11].trim()
                    };

                    const formData = new FormData();
                    Object.entries(quizResponse).forEach(([key, value]) => formData.append(key, value));
                    formData.append('action', 'add');
                    formData.append('appId', '1');
                    formData.append('bookId', record.bookid);

                    const resp2 = await fetchDataWithoutSession(restAPIs.createQuestion(formData));
                    if (resp2.statusCode === 200) {
                        message.success('Quiz created successfully.');
                    } else {
                        message.error('Failed to create quiz.');
                    }
                } else {
                    message.error('Failed to parse quiz data.');
                }
            }
        } catch (error) {
            message.error('An error occurred while creating the quiz.');
        } finally {
            setQuizLoading('');
        }
    };

    const handleAskQuiz10 = async (record: any) => {
        setQuizLoading(record.bookid);
        const quiz = `
            Make 10 questions in English and Arabic from this book. For each question, provide multiple choice answers and specify the correct answer in the following format:
            English Question:
            A)
            B)
            C)
            D)
            Arabic Question:
            A)
            B)
            C)
            D)
            Correct Answer: A) or B) or C) or D)
        `;

        try {
            const res = await fetchData(restAPIs.createAnswer(quiz, record?.bookid));
            if (res.status_code === 200) {
                const regex = /English Question:\s*(.*?)\s*A\)\s*(.*?)\s*B\)\s*(.*?)\s*C\)\s*(.*?)\s*D\)\s*(.*?)\s*Arabic Question:\s*(.*?)\s*A\)\s*(.*?)\s*B\)\s*(.*?)\s*C\)\s*(.*?)\s*D\)\s*(.*?)\s*Correct Answer:\s*(A\)|B\)|C\)|D\))/g;
                const matches: any = Array.from(res.answer.matchAll(regex));
                const formData = new FormData();
                if (matches.length > 0) {
                    for (const [index, match] of matches.entries()) {
                        formData.append('questionAr[' + index + ']', match[6].trim());
                        formData.append('questionEn[' + index + ']', match[1].trim());
                        formData.append('answerAr_1[' + index + ']', match[7].trim());
                        formData.append('answerAr_2[' + index + ']', match[8].trim());
                        formData.append('answerAr_3[' + index + ']', match[9].trim());
                        formData.append('answerAr_4[' + index + ']', match[10].trim());
                        formData.append('answerEn_1[' + index + ']', match[2].trim());
                        formData.append('answerEn_2[' + index + ']', match[3].trim());
                        formData.append('answerEn_3[' + index + ']', match[4].trim());
                        formData.append('answerEn_4[' + index + ']', match[5].trim());
                        formData.append('correctAnswer[' + index + ']', match[11].trim().replace("A)", "1").replace("B)", "2").replace("C)", "3").replace("D)", "4"));
                    }
                    formData.append('action', 'add');
                    formData.append('appId', '1');
                    formData.append('bookId', record.bookid);
                    const resp2 = await fetchDataWithoutSession(restAPIs.createQuestionTen(formData));

                    if (resp2.statusCode === 200) {
                        message.success('Quiz created successfully.');
                    } else {
                        message.error('Failed to create quiz.');
                    }
                } else {
                    message.error('Failed to parse quiz data.');
                }
            }
        } catch (error) {
            message.error('An error occurred while creating the quiz.');
        } finally {
            setQuizLoading('');
        }
    };

    const handleAskQuiz40 = async (record: any, medium:string) => {
        setQuizLoading(record.bookid);
        //catId = 1 for medium == simple, 2 for medium == intermediate, 3 for medium == tough
        let catId;
        switch (medium) {
            case 'simple':
            catId = 1;
            break;
            case 'intermediate':
            catId = 2;
            break;
            case 'tough':
            catId = 3;
            break;
            default:
            catId = 1;
        }

        const quiz = `
            Make 20 ${medium} questions in English and Arabic from this book. For each question, provide multiple choice answers and specify the correct answer in the following format:
            English Question:
            A)
            B)
            C)
            D)
            Arabic Question:
            A)
            B)
            C)
            D)
            Correct Answer: A) or B) or C) or D)
        `;

        try {
            const res = await fetchData(restAPIs.createAnswer(quiz, record?.bookid));
            if (res.status_code === 200) {
                const regex = /English Question:\s*(.*?)\s*A\)\s*(.*?)\s*B\)\s*(.*?)\s*C\)\s*(.*?)\s*D\)\s*(.*?)\s*Arabic Question:\s*(.*?)\s*A\)\s*(.*?)\s*B\)\s*(.*?)\s*C\)\s*(.*?)\s*D\)\s*(.*?)\s*Correct Answer:\s*(A\)|B\)|C\)|D\))/g;
                const matches: any = Array.from(res.answer.matchAll(regex));
                const formData = new FormData();
                if (matches.length > 0) {
                    for (const [index, match] of matches.entries()) {
                        formData.append('questionAr[' + index + ']', match[6].trim());
                        formData.append('questionEn[' + index + ']', match[1].trim());
                        formData.append('answerAr_1[' + index + ']', match[7].trim());
                        formData.append('answerAr_2[' + index + ']', match[8].trim());
                        formData.append('answerAr_3[' + index + ']', match[9].trim());
                        formData.append('answerAr_4[' + index + ']', match[10].trim());
                        formData.append('answerEn_1[' + index + ']', match[2].trim());
                        formData.append('answerEn_2[' + index + ']', match[3].trim());
                        formData.append('answerEn_3[' + index + ']', match[4].trim());
                        formData.append('answerEn_4[' + index + ']', match[5].trim());
                        formData.append('correctAnswer[' + index + ']', match[11].trim().replace("A)", "1").replace("B)", "2").replace("C)", "3").replace("D)", "4"));
                    }
                    formData.append('action', 'add');
                    formData.append('appId', '1');
                    formData.append('bookId', record.bookid);
                    formData.append('categoryId', catId.toString())
                    const resp2 = await fetchDataWithoutSession(restAPIs.createQuestionTen(formData));

                    if (resp2.statusCode === 200) {
                        message.success('Quiz created successfully.');
                    } else {
                        message.error('Failed to create quiz.');
                    }
                } else {
                    message.error('Failed to parse quiz data.');
                }
            }
        } catch (error) {
            message.error('An error occurred while creating the quiz.');
        } finally {
            setQuizLoading('');
        }
    };

    return (
        <HomepageWrapper>
            <Tabs defaultActiveKey="1" activeKey={activeKey} onChange={setActiveKey} className="__tabs_sql" tabPosition="top" color="primary">
                <TabPane tab={"Create"} key={"1"} >
                    <div className="__create_model">
                        <h1 style={{ margin: '0', fontSize: '2rem' }}>Create</h1>
                        <h1 style={{ margin: '0', fontSize: '2rem', marginBottom: '10px' }}>AI Model</h1>
                        <div className="__maxwidth">
                            <CustomSelect
                                options={booksList}
                                value={selectedBookId}
                                onChangeHandler={handleOnChangeBook}
                                label="Select Book"
                                pagination={{
                                    total: dropdownTotalItems,
                                    pageSize: dropdownPageSize
                                }}
                                loading={dropdownLoading}
                                onPaginationChange={onDropdownPageChange}
                                onSearch={onDropdownSearchTextChange}
                                onClear={onClearDropdown}
                            />
                            {selectedBookId && <h3>BookId: {selectedBookId}</h3>}
                        </div>
                        {selectedBook?.isPdfAvailable && (
                            <>
                                <h3 style={{ margin: '0' }}>PDF: </h3>
                                {selectedBook?.bookPDFs?.map((item: any) => (
                                    <a href={item?.pdfFile} target="_blank" rel="noopener noreferrer">{item?.pdfTitle}</a>
                                ))}
                            </>
                        )}
                        <div className="__upload_">
                            <h3>Select file</h3>
                            <div className="__upload_div_sec">
                                <input type="file" name="file" id="file" accept=".pdf,.docx" max={1} onChange={handleOnchangeFile} title="" ref={fileInputRef} />

                                <img src={FILE} alt="" style={{ width: '50px' }} />

                                <h3 className="ant-upload-text">Click or drag file to this area to upload</h3>
                                <p className="ant-upload-hint">For the maximum browser support, upload PDF and Docx</p>
                                <button>Select File</button>
                            </div>
                            {uploadedFile && <div className="__uploaded_list">
                                <div className="__uploaded_file">
                                    <img src={uploadedFile?.type == 'application/pdf' ? PDF : DOCX} alt="" />
                                    <span style={{ maxWidth: '350px', textOverflow: 'ellipsis', overflow: 'hidden' }}>{uploadedFile?.name}</span>
                                </div>
                                <div className="__uploaded_file __delete" onClick={() => handleRemoveFile()}>
                                    <DeleteFilled />
                                </div>
                            </div>}

                        </div>
                        <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '40px' }}>
                            {!isLoadingSubmit ? <button className="__create_model_btn" onClick={() => handleSubmit()}>Create Model</button>
                                : <button className="__create_model_btn" disabled style={{ cursor: 'no-drop' }}><LoadingOutlined /></button>}
                        </div>
                    </div>
                </TabPane>
                <TabPane tab={"Books"} key={"2"}>
                    <div className="__list_books">
                        <div className="__filter_sc">
                            <InputField
                                onChange={onChangeText}
                                value={searchText}
                                placeholder="Search"
                                color="#222"
                            />
                            <CustomSelect
                                options={filterOptions}
                                value={selectedFilter}
                                onChangeHandler={handleOnChangeFilter}
                            />
                        </div>
                        <Table
                            dataSource={booksListTable}
                            className="__market_table"
                            pagination={false}
                            rowKey="bookid"
                            loading={tableLoading}
                        >

                            <Column
                                title={
                                    <Checkbox
                                        indeterminate={indeterminate()} // Partially selected state
                                        onChange={(e) => handleSelectAll(e.target.checked)} // Select/Deselect all
                                        checked={isAllSelected()} // Checked if all are selected
                                    />
                                }
                                key="select"
                                render={(record: any) => (
                                    <Checkbox
                                        checked={isSelected(record.bookid)} // Controlled checkbox
                                        onChange={(e) => handleSelectBook(record, e.target.checked)} // Handle select/deselect
                                    />
                                )}
                            />

                            <Column title="ID" dataIndex="bookid" key="bookid" className="___col" />
                            <Column title="NAME" dataIndex="name" key="name" className="___col" />
                            <Column title="PAGES" dataIndex="totalpages" key="totalpages" className="___col" />
                            <Column
                                className="___col"
                                title="PDF"
                                key="isAiEnabled"
                                render={(record: any) => (
                                    <div className="__dots">{record?.isPdfAvailable ? <span className="green"></span> : <span className="grey"></span>}</div>
                                )}
                            />
                            <Column
                                className="___col"
                                title="TEXT"
                                key="isAiEnabled"
                                render={(record: any) => (
                                    <div className="__dots">{record?.isTextAvailable ? <span className="green"></span> : <span className="grey"></span>}</div>
                                )}
                            />
                            <Column
                                className="___col"
                                title="LINK"
                                key="name"
                                render={(record: any) => (
                                    <a href={"https://alwaraq.net/books/" + record?.bookid} target="_blank" rel="noreferrer"><LinkOutlined /></a>
                                )}
                            />
                            <Column
                                className="___col"
                                title="QUESTION"
                                key="name"
                                render={(record: any) => (

                                    <div style={{display:'flex', gap:'10px', alignItems:'center'}}>
                                    <Tooltip title='Simple Quiz'><button className="__enabled orange" style={{ cursor: 'pointer' }} onClick={() => handleAskQuiz40(record, 'simple')}>{quizLoading == record.bookid ? <LoadingOutlined /> : 'S'}</button></Tooltip>
                                    <Tooltip title='Intermediate Quiz'><button className="__enabled green" style={{ cursor: 'pointer' }} onClick={() => handleAskQuiz40(record, 'intermediate')}>{quizLoading == record.bookid ? <LoadingOutlined /> : 'I'}</button></Tooltip>
                                    <Tooltip title='Expert Quiz'><button className="__enabled red" style={{ cursor: 'pointer' }} onClick={() => handleAskQuiz40(record,'tough')}>{quizLoading == record.bookid ? <LoadingOutlined /> : 'E'}</button></Tooltip>
                                    </div>
                                    
                                    // <button className="__enabled" style={{ cursor: 'pointer' }} onClick={() => handleAskQuiz10(record)}>{quizLoading == record.bookid ? <LoadingOutlined /> : 'Ask Quiz'}</button>
                                )}
                            />
                            <Column
                                className="___col"
                                title="QUIZ Enabled"
                                key="name"
                                render={(record: any) => (
                                    <Tag color={record.isQuizAvailable === 1 ? 'success' : 'error'}>
                                        {record.isQuizAvailable === 1 ? "Enabled" : "Not Enabled"}
                                    </Tag>
                                )}
                            />
                            <Column
                                className="___col_status"
                                title="AI Status"
                                key="isAiEnabled"
                                render={(record: any) => (
                                    <>{record?.isAiEnabled === 0 ? <button className="__hyper" onClick={() => handleRowClick(record)}>Create Model</button> : <button className="__enabled">Enabled</button>}</>
                                )}
                            />
                        </Table>
                        <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
                            <Pagination
                                current={tablePage}
                                total={tableTotalItems}
                                pageSize={tablePageSize}
                                onChange={onTablePageChange}
                                showSizeChanger={false}
                                style={{ textAlign: 'right' }}
                            />
                        </div>


                        {selectedBooks.length > 0 && (<div style={{ marginTop: '20px' }}>

                            <h4>Selected Books</h4>

                            <div style={{ display: 'flex', flexWrap: 'wrap', gap: '16px' }}>
                                {selectedBooks.map(book => (
                                    <div key={book.bookid} style={{
                                        display: 'flex', alignItems: 'center',
                                        border: '1px solid #eee',
                                        padding: '10px',
                                        borderRadius: '10px'
                                    }}>
                                        <Avatar style={{ background: '#555' }}>{book.name[0]}</Avatar> {/* Avatar displaying first letter of name */}
                                        <Tooltip title={book.name}> {/* Tooltip with full book name */}
                                            <span style={{ margin: '0 10px' }}>
                                                {book.name.length > 14 ? `${book.name.substring(0, 14)}...` : book.name}
                                            </span>
                                        </Tooltip>
                                        <Button
                                            size="small"
                                            onClick={() => handleRemoveBook(book.bookid)} // Remove button
                                            icon={<DeleteFilled />}
                                        >

                                        </Button>
                                    </div>
                                ))}
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
                                {!isLoadingSubmit ? <button className="__create_model_btn" style={{ height: '54px' }} onClick={() => handleBatchSubmit()}>Create Model</button>
                                    : <button className="__create_model_btn" disabled style={{ height: '54px', cursor: 'no-drop' }}><LoadingOutlined /></button>}
                            </div>
                        </div>

                        )}

                    </div>
                </TabPane>
            </Tabs>

            <Modal
                visible={isLoadingSubmit}
                footer={null}
                closable={false}
                centered
                className="__model"
            >
                <div className="__model_design">
                    <h1>{modeltext}</h1>
                    <img src={modelstatus ? CHECKMARK : LOADER} alt="" style={{ filter: modelstatus ? 'hue-rotate(0deg)' : 'hue-rotate(106deg)' }} />
                    <p style={{ marginBottom: '0px' }}>{modeldescription}</p>
                    <h2 style={{ marginTop: '0px' }}>{modeldescription2}</h2>
                    <div style={{ display: 'flex', justifyContent: 'end' }}>
                        {modelstatus && <button className="__ok_button" onClick={() => { setIsLoadingSubmit(false); setModelStatus(false) }}>Close</button>}
                    </div>
                </div>
            </Modal>
        </HomepageWrapper>
    );
};

export default Homepage;